<template>
<AppLayout>
    <template v-slot:appContent>
        <div>

            <div class="row">
                <div class="col-12" v-if="panelBaseTodayAppointmentsReportData.length">
                    <div class="card box-shadow-0">
                        <div class="card-body">
                            <h5 class="p-0 m-0 text-primary">Appointments Summary <small class="text-dark">({{currentDate()}})</small></h5>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="" v-if="panelBaseTodayAppointmentsReportData.length">
                        <div class="flexbox5">
                            <div v-for="(panel,index) in panelBaseTodayAppointmentsReportData" :key="index" class="">
                                <!-- {{panel}} -->
                                <div class="card text-center ">
                                    <div class="pt-2">
                                        <h4 class="text-center">{{panel.label}}: <span :class="panel.status.type === 'Increase' ? 'text-success' : `${panel.status.type === 'Monotonic' ? 'text-warning' :'text-danger' }`">{{panel.status.value}}</span>
                                            <span v-if="panel.status.type === 'Increase'"> <i class='bx bx-trending-up text-success  ' style="font-size: 26px;"></i></span>
                                            <span v-else-if="panel.status.type === 'Monotonic'"> <i class='bx bx-stats text-warning  ' style="font-size: 26px;"></i></span>
                                            <span v-else> <i class='bx bx-trending-down text-danger  ' style="font-size: 26px;"></i></span>
                                        </h4>
                                    </div>
                                    <div class="card-body py-1">
                                        <!-- <h6>Today : {{panel.currentDayTotal}}</h6>
                                        <h6>Preious day : {{panel.previousDayTotal}}</h6> -->
                                        <table class="table table-sm table-bordered">
                                            <thead class="thead-success">
                                                <tr>
                                                    <th scope="col">Yesterday</th>
                                                    <th scope="col">Today</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="table-active">
                                                    <td scope="row">{{panel.previousDayTotal}}</td>
                                                    <td scope="row"> {{panel.currentDayTotal}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- <div class="badge-circle badge-circle-lg badge-circle-light-success mx-auto mb-50">
                                            <i class='bx bx-briefcase bx-tada'></i>
                                        </div>
                                        <div class="text-muted line-ellipsis">Ongoing appointments today</div>
                                        <h3 class="mb-0">5</h3> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 row">
                    <div class="col-md-6 col-lg-6" v-if="Object.keys(this.panelBaseMonthlyAppointmentsReport).length !== 0">
                        <div class="card box-shadow-0">
                            <div class="card-body">

                                <h5 class="p-0 m-0 text-primary">Monthly Appointment Creation Rate.</h5>

                                <div>
                                    <!-- <div id="">
                                    <canvas id="panelBasseMonthlyAppointmentCanvasId"></canvas>
                                </div> -->
                                    <div id="panelBasseMonthlyAppointmentCanvasId">
                                        <!-- <canvas id="technicianWiseAppointmentChartId"></canvas> -->
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6" v-if="panelBaseYearlyAppointmentsReport.length">

                        <div class="card box-shadow-0">
                            <div class="card-body">

                                <h5 class="p-0 m-0 text-primary">Appointments Creation In The Year {{currentDate().slice(0,-6)}} </h5>

                                <div>
                                    <!-- <div id="">
                                    <canvas id="panelBasseMonthlyAppointmentCanvasId"></canvas>
                                </div> -->
                                    <div id="panelBaseYearlyAppointmentCanvasId">
                                        <!-- <canvas id="technicianWiseAppointmentChartId"></canvas> -->
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="col-12" v-if="panelBaseWeeklyAppointmentsReportData.length">
                    <div class="card box-shadow-0">
                        <div class="card-body">
                            <h5 class="p-0 m-0 text-primary">Appointments Weekly Summary</h5>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 col-12 dashboard-greetings" v-for="(panel,index) in  panelBaseWeeklyAppointmentsReportData" :key="index">
                    <div class="card">

                        <div class="card-body ">
                            <div class="text-right">
                                <i class='bx bx-show cursor-pointer text-info' title="view" @click="openDashboardSummerySingleViewModal(panel)" style="font-size: 24px;"></i>
                            </div>
                            <h5>{{panel.label}} <span class="text-primary">({{panel.currentTotal}})</span> <span :class="panel.status.type === 'Increase' ? 'text-success' :'text-danger'">{{panel.status.value}}</span>
                                <span v-if="panel.status.type === 'Increase'"> <i class='bx bx-trending-up text-success bx-tada bx-flip-horizontal' style="font-size: 26px;"></i></span>
                                <span v-else> <i class='bx bx-trending-down text-danger bx-tada bx-flip-horizontal' style="font-size: 26px;"></i></span>
                            </h5>

                            <div>
                                <div id="">
                                    <canvas height="300px" :id="panel.canvasId"></canvas>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 dashboard-greetings">
                    <div class="card">

                        <div class="card-body ">
                            <h4 class="pb-1 text-primary">Appointments made on today <small class="text-dark">({{currentDate()}})</small></h4>
                            <div class="users-list-table">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table id="users-list-datatable" class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="position-relative" style="width: 5%">SL</th>
                                                        <th class="position-relative" style="width: 20%">Customer</th>
                                                        <th class="position-relative" style="width: 10%">Service</th>
                                                        <th class="position-relative" style="width: 10%">Panel</th>
                                                        <th class="position-relative" style="width: 15%">Job Location</th>
                                                        <th class="position-relative" style="width: 15%">Technician</th>
                                                        <th class="position-relative" style="width: 5%">Status</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr v-for="(appointment, index) in appointments" :key="index">
                                                        <td>{{ index + 1 }}</td>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <span>
                                                                    <i class="bx" :class="appointment.type === 'Home' ? 'bx-home text-warning' : 'bxs-business text-success'"></i>
                                                                </span>
                                                                <router-link title="Click to view customer details" :to="{name:'appCustomerView',params: { id: appointment.customer.id }}" v-if="appointment.customer && appointment.customer.user">
                                                                    {{ `${appointment.customer.user.first_name} ${appointment.customer.user.last_name}` }}
                                                                </router-link>
                                                            </div>
                                                            <div>

                                                                <small title="Click to view " class="text-secondary cursor-pointer" @click="vieAppointmentFullDetailsHandler(appointment.reference)">
                                                                    Reference: #{{ appointment.reference }}
                                                                </small>

                                                            </div>
                                                            <div>
                                                                <small>
                                                                    Service date : {{ `${appointment.date} ${appointment.time}` }}
                                                                </small>
                                                            </div>
                                                            <div>
                                                                <small>
                                                                    Creation date : {{ appointment.created_at ? appointment.created_at : '-' }}
                                                                </small>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            {{ appointment.service && appointment.service.name ? appointment.service.name : '-' }}
                                                        </td>
                                                        <td>
                                                            <div v-if="appointment?.appointmentCreator?.panel">
                                                                <span class="text-success" v-if="appointment.appointmentCreator.panel === 'Online Appointment Form'">{{appointment?.appointmentCreator?.panel}}</span>
                                                                <span class="text-primary" v-if="appointment.appointmentCreator.panel === 'System Web-App'">{{appointment?.appointmentCreator?.panel}}</span>
                                                                <span class="text-info" v-if="appointment.appointmentCreator.panel === 'Technician Mob-App'">{{appointment?.appointmentCreator?.panel}}</span>
                                                                <span class="text-secondary" v-if="appointment.appointmentCreator.panel === 'Customer Web-App'">{{appointment?.appointmentCreator?.panel}}</span>
                                                            </div>
                                                            <span v-else>-</span>

                                                        </td>
                                                        <td>
                                                            <div v-if="appointment.preference === 'On-Site'">
                                                                <span v-if="appointment.address">
                                                                   {{ buildAddress(appointment.address)}}
                                                                </span>
                                                            </div>
                                                            <span v-else class="badge badge-pill">
                                                                {{ appointment.preference }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div v-if="appointment?.technicianAppointment?.id">
                                                                {{`${appointment.technicianAppointment.technician.user.first_name} ${appointment.technicianAppointment.technician.user.last_name}`}}
                                                            </div>
                                                            <div v-else>
                                                                <span class="badge badge-pill badge-danger">To be assign</span>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <span class="badge badge-pill" :class="appointmentStatusBadgeColour(appointment.status)">
                                                                {{ appointment.status }}
                                                            </span>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-12 dashboard-greetings">
                    <div class="card">
                        <div class="card-body ">
                            <h4 class="pb-1 text-primary">Today's ongoing appointments <small class="text-dark">({{currentDate()}})</small></h4>
                            <div class="users-list-table">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table id="users-list-datatable" class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="position-relative" style="width: 5%">SL</th>
                                                        <th class="position-relative" style="width: 20%">Customer</th>
                                                        <th class="position-relative" style="width: 10%">Service</th>
                                                        <th class="position-relative" style="width: 10%">Panel</th>
                                                        <th class="position-relative" style="width: 15%">Job Location</th>
                                                        <th class="position-relative" style="width: 15%">Technician</th>
                                                        <th class="position-relative" style="width: 5%">Status</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr v-for="(appointment, index) in todayOngoignAppointments" :key="index">
                                                        <td>{{ index + 1 }}</td>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <span>
                                                                    <i class="bx" :class="appointment.type === 'Home' ? 'bx-home text-warning' : 'bxs-business text-success'"></i>
                                                                </span>
                                                                <router-link title="Click to view customer details" :to="{name:'appCustomerView',params: { id: appointment.customer.id }}" v-if="appointment.customer && appointment.customer.user">
                                                                    {{ `${appointment.customer.user.first_name} ${appointment.customer.user.last_name}` }}
                                                                </router-link>
                                                            </div>
                                                            <div>

                                                                <small title="Click to view " class="text-secondary cursor-pointer" @click="vieAppointmentFullDetailsHandler(appointment.reference)">
                                                                    Reference: #{{ appointment.reference }}
                                                                </small>

                                                            </div>
                                                            <div>
                                                                <small>
                                                                    Service date : {{ `${appointment.date} ${appointment.time}` }}
                                                                </small>
                                                            </div>
                                                            <div>
                                                                <small>
                                                                    Creation date : {{ appointment.created_at ? appointment.created_at : '-' }}
                                                                </small>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            {{ appointment.service && appointment.service.name ? appointment.service.name : '-' }}
                                                        </td>
                                                        <td>
                                                            <div v-if="appointment?.appointmentCreator?.panel">
                                                                <span class="text-success" v-if="appointment.appointmentCreator.panel === 'Online Appointment Form'">{{appointment?.appointmentCreator?.panel}}</span>
                                                                <span class="text-primary" v-if="appointment.appointmentCreator.panel === 'System Web-App'">{{appointment?.appointmentCreator?.panel}}</span>
                                                                <span class="text-info" v-if="appointment.appointmentCreator.panel === 'Technician Mob-App'">{{appointment?.appointmentCreator?.panel}}</span>
                                                                <span class="text-secondary" v-if="appointment.appointmentCreator.panel === 'Customer Web-App'">{{appointment?.appointmentCreator?.panel}}</span>
                                                            </div>
                                                            <span v-else>-</span>

                                                        </td>
                                                        <td>
                                                            <div v-if="appointment.preference === 'On-Site'">
                                                                <span v-if="appointment.address">
                                                                    {{ buildAddress(appointment.address)}}
                                                                </span>
                                                            </div>
                                                            <span v-else class="badge badge-pill">
                                                                {{ appointment.preference }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div v-if="appointment?.technicianAppointment?.id">
                                                                {{`${appointment.technicianAppointment.technician.user.first_name} ${appointment.technicianAppointment.technician.user.last_name}`}}
                                                            </div>
                                                            <div v-else>
                                                                <span class="badge badge-pill badge-danger">To be assign</span>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <span class="badge badge-pill" :class="appointmentStatusBadgeColour(appointment.status)">
                                                                {{ appointment.status }}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 dashboard-greetings">
                    <div class="card">

                        <div class="card-body ">
                            <h4 class="pb-1 text-primary">Today's ongoing orders <small class="text-dark">({{currentDate()}})</small></h4>
                            <div class="users-list-table">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table id="users-list-datatable" class="table">
                                    <thead>
                                        <tr>
                                            <th class="position-relative" :style="{width:'5%'}">SL</th>
                                            <th class="position-relative" :style="{width:'10%'}">ORDER ID</th>
                                            <th class="position-relative" :style="{width:'30%'}">CUSTOMER</th>
                                            <th class="position-relative" :style="{width:'15%'}">TECHNICIAN</th>
                                            <th class="position-relative" :style="{width:'15%'}">ORDER DATE</th>
                                            <th class="position-relative" :style="{width:'10%'}">DELIVERY DATE</th>
                                            <th class="position-relative" :style="{width:'10%'}">STATUS</th>
                                            <!-- <th class="position-relative" :style="{width:'15%'}"> PRICE</th> -->
                                            <!-- <th class="position-relative" :style="{width:'5%'}">Actions</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(order,index) in orders" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td>

                                                <small> # {{ order.order_no }}</small>

                                            </td>
                                            <td>
                                                <router-link title="Click to view customer details" :to="{name:'appCustomerView',params: { id: order.user.customer.id }}" class="text-capitalize">{{ (order.user.full_name) }}
                                                </router-link>
                                                <br>
                                                <span class="text-light">{{ userFullAddress(order.user.customer) }}</span>
                                            </td>

                                            <td><span class="badge" :class="order.appointment === null ? 'badge-light-glow':'badge-light-warning'"> {{
                        order.appointment !== null ? order.appointment?.technicianAppointment?.technician?.user?.full_name : 'Individual'
                      }}</span></td>
                                            <td>
                                                <span class="badge-light-light">{{ getDisplayDate(order.order_date) }}</span>
                                            </td>
                                            <td>
                                                <span class="">{{ getDisplayDate(order.delivery_date) }}</span>
                                            </td>
                                            <td>
                                                <span :class="order.status==='Pending'? 'badge badge-light-success badge-pill':'badge badge-light-danger badge-pill'">{{
                          order.status
                        }}</span>
                                            </td>

                                            <!-- <td v-if="payments[order.id]!== undefined">
                                                <span>
                                                    <span class="text">
                                                        Total: ${{ parseFloat(payments[order.id].total / 100).toFixed(2) }}
                                                    </span><br>
                                                    <span class="text-success">
                                                        Paid: ${{ parseFloat(payments[order.id].paid / 100).toFixed(2) }}
                                                    </span><br>
                                                    <span v-if="payments[order.id].remaining !== 0" class="text-danger">
                                                        Remaining: ${{ parseFloat(payments[order.id].remaining / 100).toFixed(2) }}
                                                    </span>
                                                    <span v-else class="text-success">(Fully Paid)</span>
                                                </span><br>
                                                <span v-if="refunds[order.id] !== undefined && refunds[order.id].status === true">
                                                    <span class="text-warning">
                                                        Refund: ${{ parseFloat(refunds[order.id].amount / 100).toFixed(2) }}
                                                    </span>
                                                    <br>
                                                </span>
                                            </td> -->

                                        </tr>
                                    </tbody>
                                </table>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div data-toggle="modal" data-target="#dashboardSummerySingleViewModal"></div>
        <DashboardSummerySingleView :currentSelectedPanel="currentSelectedPanel" />
    </template>
</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

import Chart from 'chart.js/auto';
// import annotationPlugin from 'chartjs-plugin-annotation';

// Chart.register(annotationPlugin);
import {
    mapActions,
    mapGetters
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
import DashboardSummerySingleView from './includes/DashboardSummerySingleView.vue';
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";

export default {
    name: "AppDashboard",
    mixins: [ShowToastMessage, Loader, Authorization, AddressMixin],
    components: {
        AppLayout,
        DashboardSummerySingleView
    },
    data() {
        return {
            currentSelectedPanel: {},
            graphicalView: true,
            panelWiseAppointmentWeekData: [],
            panelBaseWeeklyAppointmentsReportData: [],
            panelBaseMonthlyAppointmentsReportData: {

            },
            panelBaseTodayAppointmentsReportData: [],
            panelBaseYearlyAppointmentsReportData: {},
            // panelBaseAppointmentMonthlyData: [],
            panelWiseAppointmentDailyData: [],

            getAppointmentsParams: {
                created_at: [],
                date: [],
                where_has_franchisee_id: '',
                with_relation: ["customer.user", "customer.address", "billingAddress", "order", "service", "address",
                    "appointmentCreator",
                    "technicianAppointment.technician.user",
                ],
                order_by_time: 'ASC',
                order_by_date: 'ASC',

            },
            todayOngoignAppointments: [],
            getOrdersParams: {
                status: '',
                order_no: '',
                where_has_user_search_query: '',
                where_has_appointment_technician_id: '',
                order_date: [],
                delivery_date: [],
                with_relation: [
                    'user',
                    'user.customer.address',
                    'user.customer.address.business',
                    'orderCharges',
                    'orderPayments.payment',
                    'orderPaymentsByReference',
                    'appointment.technicianAppointment.technician.user',
                    'shippingAddress.business',
                    'billingAddress.business',
                    'orderNotes',
                    'orderProductItems.productItem.product',
                    'orderRefundsByReference',
                    'appointment.technicianAppointment.technician.franchiseeTechnician', // check order tag appointment by franchisee technician
                    'orderCreator.user.franchisee' // check franchisee create an order
                ],
                order_by_id: 'DESC',

            },
            payments: {},
            refunds: {},

        }

    },
    computed: {
        ...mapGetters({
            appointments: "appAppointments/appointments",
            authFranchisee: 'appAuthLogin/authFranchisee',
            panelBaseWeeklyAppointmentsReport: "appDashboards/panelBaseWeeklyAppointmentsReport",
            panelBaseMonthlyAppointmentsReport: "appDashboards/panelBaseMonthlyAppointmentsReport",
            panelBaseYearlyAppointmentsReport: "appDashboards/panelBaseYearlyAppointmentsReport",
            panelBaseTodayAppointmentsReport: "appDashboards/panelBaseTodayAppointmentsReport",
            todayCreatedAppointments: "appDashboards/todayCreatedAppointments",
            todayOngoingAppointments: "appDashboards/todayOngoingAppointments",
            todayCreatedOrders: "appDashboards/todayCreatedOrders",
            orders: 'appOrders/orders',
        })

    },
    watch: {

    },
    methods: {
        ...mapActions({

            getAppointments: "appAppointments/getAppointments",
            getTodayOngoingAppointments: "appAppointments/getTodayOngoingAppointments",
            resetAppointments: "appAppointments/resetAppointments",
            getUserType: "appUsers/getUserType",
            getPanelBaseWeeklyAppointmentsReport: "appDashboards/getPanelBaseWeeklyAppointmentsReport",
            getPanelBaseMonthlyAppointmentsReport: "appDashboards/getPanelBaseMonthlyAppointmentsReport",
            getPanelBaseTodayAppointmentsReport: "appDashboards/getPanelBaseTodayAppointmentsReport",
            getPanelBaseYearlyAppointmentsReport: "appDashboards/getPanelBaseYearlyAppointmentsReport",
            getTodayCreatedAppointemnts: "appDashboards/getTodayCreatedAppointemnts",
            getTodayCreatedOrder: "appDashboards/getTodayCreatedOrder",
            getTodayOngoingAppointemnts: "appDashboards/getTodayOngoingAppointemnts",
            getOrders: 'appOrders/getOrders',
            resetOrders: 'appOrders/resetOrders',

        }),

      currentDate() {
        const today = new Date();
        let currentDate = today.getDate();
        let currentMonth = today.getMonth()+1;
        let currentFullYear = today.getFullYear();

        if (currentMonth < 10) {
          currentMonth = `0${currentMonth}`;
        }

        if(currentDate <10) {
          currentDate = `0${currentDate}`;
        }
        return `${currentFullYear}-${currentMonth}-${currentDate}`;
      },

        appointmentStatusBadgeColour(status) {
            if (status === 'Open')
                return 'badge-light-warning';
            else if (status === 'Cancelled')
                return 'badge-light-danger';
            else if (status === 'Closed')
                return 'badge-light-success';
            else if (status === 'Credited')
                return 'badge-light-primary';
            else if (status === 'Delivered')
                return 'badge-light-success';
            else if (status === 'Dispute')
                return 'badge-light-danger';
            else if (status === 'Hold')
                return 'badge-light-danger';
            else if (status === 'Reassigned')
                return 'badge-light-info';
            else if (status === 'Refund')
                return 'badge-light-secondary';
            else if (status === 'Rescheduled')
                return 'badge-light-info';
            else if (status === 'Rescheduled & Reassigned')
                return 'badge-light-info';
            else
                return 'badge-light-secondary';
        },

        async getUserTypes() {
            const paramObj = {
                id: this.$store.getters["appAuthLogin/authUser"].id,
            };
            this.loader(true);
            await this.getUserType(paramObj.id);
            this.loader(false);
        },

        async panelBaseWeeklyAppointmentsReportHandler() {
            this.loader(true);
            const response = await this.getPanelBaseWeeklyAppointmentsReport({
                franchisee_id: this.authFranchisee.id,
                day_chunk: 7,
                start_date: this.currentDate()
            })
            this.loader(false);

            if (response.message && response.status !== 200) {
                this.showToastMessage(response);
            }
            if (Object.keys(this.panelBaseWeeklyAppointmentsReport).length !== 0) {
                for (let key in this.panelBaseWeeklyAppointmentsReport) {
                    this.panelBaseWeeklyAppointmentsReportData.push({
                        panelFor: 'week',
                        chartType: 'double',
                        chartName: 'bar',
                        label: key,
                        canvasId: key.toString().replace(/\s+/g, '') + 'week',
                        datasets: [this.panelBaseWeeklyAppointmentsReport[key].current.map((item) => item.count), this.panelBaseWeeklyAppointmentsReport[key].previous.map((item) => item.count)],
                        dates: [this.panelBaseWeeklyAppointmentsReport[key].current.map((item) => item.date), this.panelBaseWeeklyAppointmentsReport[key].previous.map((item) => item.date)],
                        dayNames: [this.panelBaseWeeklyAppointmentsReport[key].current.map((item) => item.day_name), this.panelBaseWeeklyAppointmentsReport[key].previous.map((item) => item.day_name)],
                        total: this.panelBaseWeeklyAppointmentsReport[key].total,
                        status: this.panelBaseWeeklyAppointmentsReport[key].status,
                        labels: this.panelBaseWeeklyAppointmentsReport[key].previous.map((item) => item.day_name),
                        labelTitle: [`This week ${key}`, `previous week ${key}`],
                        currentTotal: this.panelBaseWeeklyAppointmentsReport[key].current_total,
                    });
                    // console.log(key, this.panelBaseWeeklyAppointmentsReport[key]);
                }

                this.panelBaseWeeklyAppointmentsReportData.map((panel) => {
                    this.$nextTick(function () {
                        new Chart(document.getElementById(panel.canvasId), this.generateChart({
                            type: panel.chartType,
                            data: panel.datasets
                        }, panel.labels, panel.chartName, [`This week ${panel.label}`, `Previous week ${panel.label}`], true, ['#36a2eb', '#ff6384']));
                        // console.log(document.getElementById(panel.canvasId));
                    })

                })

            }

        },
        async panelBaseMonthlyAppointmentsReportHandler() {
            this.loader(true);
            const response = await this.getPanelBaseMonthlyAppointmentsReport({
                franchisee_id: this.authFranchisee.id,

                requested_year_month: this.currentDate().slice(0, -3) // example: 2022-09,
            })
            this.loader(false);

            if (response.message && response.status !== 200) {
                this.showToastMessage(response);
            }
            if (Object.keys(this.panelBaseMonthlyAppointmentsReport).length !== 0) {
                this.panelBaseMonthlyAppointmentsReportData.panelFor = 'month';
                this.panelBaseMonthlyAppointmentsReportData.chartType = 'double';
                this.panelBaseMonthlyAppointmentsReportData.chartName = 'line';
                this.panelBaseMonthlyAppointmentsReportData.label = '';
                this.panelBaseMonthlyAppointmentsReportData.datasets = [];
                this.panelBaseMonthlyAppointmentsReportData.lables = [];
                this.panelBaseMonthlyAppointmentsReportData.dates = [];
                this.panelBaseMonthlyAppointmentsReportData.labelTitle = [];
                let index = 0;
                for (let key in this.panelBaseMonthlyAppointmentsReport) {
                    this.panelBaseMonthlyAppointmentsReportData.labelTitle[index] = key;
                    const singleMonthsData = [];
                    for (let reportKey in this.panelBaseMonthlyAppointmentsReport[key].report_data) {
                        singleMonthsData.push(this.panelBaseMonthlyAppointmentsReport[key].report_data[reportKey].average);
                        // this.panelBaseMonthlyAppointmentsReportData.datasets[index] = (this.panelBaseMonthlyAppointmentsReport[key].report_data[reportKey].average);
                        // console.log(this.panelBaseMonthlyAppointmentsReport[key].report_data[reportKey].date, "reportKey");
                    }
                    this.panelBaseMonthlyAppointmentsReportData.datasets[index] = singleMonthsData;
                    index++;

                }
                const labelLength = this.panelBaseMonthlyAppointmentsReportData.datasets.reduce(((result, curent) => curent.length > result ? curent.length : result), 0)
                this.panelBaseMonthlyAppointmentsReportData.lables = Array.from({
                    length: labelLength
                }, (_, i) => i + 1)

                // console.log(this.generateChart({
                //     type: this.panelBaseMonthlyAppointmentsReportData.chartType,
                //     data: this.panelBaseMonthlyAppointmentsReportData.datasets
                // }, this.panelBaseMonthlyAppointmentsReportData.lables, this.panelBaseMonthlyAppointmentsReportData.chartName, [``, ``], true, ['#4bc0c0', '#36a2eb']));

                document.querySelector("#panelBasseMonthlyAppointmentCanvasId").innerHTML = ' <canvas id="panelBasseMonthlyAppointmentCanvas" height="300px"></canvas>';
                new Chart(document.getElementById("panelBasseMonthlyAppointmentCanvas"), this.generateChart({
                    type: this.panelBaseMonthlyAppointmentsReportData.chartType,
                    data: this.panelBaseMonthlyAppointmentsReportData.datasets
                }, this.panelBaseMonthlyAppointmentsReportData.lables, this.panelBaseMonthlyAppointmentsReportData.chartName, this.panelBaseMonthlyAppointmentsReportData.labelTitle, true, ['#36a2eb', '#ff6384']));

            }

        },
        async panelBaseTodayAppointmentsReportHandler() {
            this.loader(true);
            const response = await this.getPanelBaseTodayAppointmentsReport({
                franchisee_id: this.authFranchisee.id,
                day_chunk: 1,
                start_date: this.currentDate()
            })
            this.loader(false);

            if (response.message && response.status !== 200) {
                this.showToastMessage(response);
            }
            if (Object.keys(this.panelBaseTodayAppointmentsReport).length !== 0) {
                for (let key in this.panelBaseTodayAppointmentsReport) {
                    this.panelBaseTodayAppointmentsReportData.push({

                        label: key,
                        currentDayTotal: this.panelBaseTodayAppointmentsReport[key].current.reduce(((result, current) => result + current.count), 0),
                        previousDayTotal: this.panelBaseTodayAppointmentsReport[key].previous.reduce(((result, current) => result + current.count), 0),
                        total: this.panelBaseTodayAppointmentsReport[key].total,
                        status: this.panelBaseTodayAppointmentsReport[key].status,

                        // datasets: [this.panelBaseTodayAppointmentsReport[key].current.map((item) => item.count), this.panelBaseTodayAppointmentsReport[key].previous.map((item) => item.count)],
                        // dates: [this.panelBaseTodayAppointmentsReport[key].current.map((item) => item.date), this.panelBaseTodayAppointmentsReport[key].previous.map((item) => item.date)],
                        // dayNames: [this.panelBaseTodayAppointmentsReport[key].current.map((item) => item.day_name), this.panelBaseTodayAppointmentsReport[key].previous.map((item) => item.day_name)],

                        // labels: this.panelBaseTodayAppointmentsReport[key].previous.map((item) => item.date),
                        // labelTitle: [`current ${key}`, `previous ${key}`]
                    });
                    // console.log(key, this.panelBasemMonthlyAppointmentsReport[key]);

                }

            }
            const panelList = [
                'System Web App', 'Technician Mob App', 'Customer Web App', 'Customer Mob App', 'Online Appointment'
            ];

            const remainingPanelList = panelList.filter((panel) => this.panelBaseTodayAppointmentsReportData.find((todayPanel) => todayPanel.label === panel) !== undefined ? false : true);
            remainingPanelList.forEach(item => {
                this.panelBaseTodayAppointmentsReportData.push({
                    label: item,
                    currentDayTotal: 0,
                    previousDayTotal: 0,
                    total: 0,
                    status: {
                        value: "0.00 %",
                        type: "Monotonic"
                    }
                });
            });
            // console.log(remainingPanelList, "Online AppointmentOnline AppointmentOnline AppointmentOnline Appointment");

        },
        async panelBaseYearlyAppointmentsReportHandler() {
            this.loader(true);
            const response = await this.getPanelBaseYearlyAppointmentsReport({
                franchisee_id: this.authFranchisee.id,
                requested_year: this.currentDate().slice(0, -6)
            })
            this.loader(false);

            if (response.message && response.status !== 200) {
                this.showToastMessage(response);
            }
            if (this.panelBaseYearlyAppointmentsReport.length !== 0) {
                // this.panelBaseYearlyAppointmentsReportData
                this.panelBaseYearlyAppointmentsReportData.panelFor = 'Yearly';
                this.panelBaseYearlyAppointmentsReportData.chartType = 'single';
                this.panelBaseYearlyAppointmentsReportData.chartName = 'pie';
                this.panelBaseYearlyAppointmentsReportData.label = 'adfadf';
                this.panelBaseYearlyAppointmentsReportData.datasets = [];
                this.panelBaseYearlyAppointmentsReportData.lables = [];
                this.panelBaseYearlyAppointmentsReportData.dates = [];
                this.panelBaseYearlyAppointmentsReportData.labelTitle = [];

                this.panelBaseYearlyAppointmentsReport.map((item) => {
                    this.panelBaseYearlyAppointmentsReportData.datasets.push(item.count);
                    this.panelBaseYearlyAppointmentsReportData.lables.push(item.month);
                })

                document.querySelector("#panelBaseYearlyAppointmentCanvasId").innerHTML = ' <canvas id="panelBasYearlyAppointmentCanvas" height="300px"></canvas>';
                new Chart(document.getElementById("panelBasYearlyAppointmentCanvas"), this.generateChart({
                    type: this.panelBaseYearlyAppointmentsReportData.chartType,
                    data: this.panelBaseYearlyAppointmentsReportData.datasets
                }, this.panelBaseYearlyAppointmentsReportData.lables, this.panelBaseYearlyAppointmentsReportData.chartName, this.panelBaseYearlyAppointmentsReportData.labelTitle, true, ['#36a2eb', '#ff6384']));

            }

        },
        generateChart(dataset, labels, type, label, animation, colors) {

            const chart = {
                type: type,
                data: {
                    labels: labels,
                    datasets: [],
                },
                options: {

                    responsive: true,
                    maintainAspectRatio: false,
                    showAllTooltips: false,
                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            align: 'top',
                            formatter: Math.round,
                            font: {
                                weight: 'bold'
                            }
                        }
                    },

                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            };
            if (dataset.type === 'double') {

                dataset.data.map((item, index) => {
                    chart.data.datasets.push({
                        tension: 0.5,
                        label: label[index],
                        fill: false,
                        data: dataset.data[index],
                        backgroundColor: [
                            `${colors[index]}`,
                        ],
                        borderColor: [
                            `${colors[index]}`,

                        ],
                        borderWidth: 2

                    });
                })

            } else {

                chart.data.datasets.push({
                    label: label,
                    // fill: false,
                    data: dataset.data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 2
                });

            }

            if (animation) {
                chart.animation = {
                    // tension: {
                    //     easing: 'linear',
                    //     from: 0,
                    //     to: 1,

                    // }
                }
            }

            return chart;
        },

        async getTodayCreatedAppointemntsList() {
            if (this.authFranchisee.id) {
                this.getAppointmentsParams.where_has_franchisee_id = this.authFranchisee.id;
            }
            this.getAppointmentsParams.created_at[0] = this.currentDate();
            this.loader(true);
            await this.getAppointments(this.getAppointmentsParams).then((response) => {
                this.loader(false);
                if (response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });

        },
        async getTodayCreatedOrderList() {
            // if (this.authFranchisee.id) {
            //     this.getOrdersParams.where_has_franchisee_throw_appointment_technician_or_creator = this.authFranchisee.id;
            // }
            this.loader(true);
            const response = await this.getTodayCreatedOrder({
                where_has_franchisee_throw_appointment_technician_or_creator: this.authFranchisee ?.id ?? null
            });
            this.loader(false);
            if (response.message && response.status !== 200) {
                this.showToastMessage(response);
            }
        },
        async getTodayOngoingAppointemntsList() {
            if (this.authFranchisee.id) {
                this.getAppointmentsParams.where_has_franchisee_id = this.authFranchisee.id;
            }
            this.getAppointmentsParams.created_at = [];
            this.getAppointmentsParams.date[0] = this.currentDate();
            this.getAppointmentsParams.date[1] = this.currentDate();
            this.loader(true);
            await this.getTodayOngoingAppointments(this.getAppointmentsParams).then((response) => {
                this.loader(false);
                if (response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
                if (response.status === 200) {
                    // console.log(response.data.data);
                    this.todayOngoignAppointments = response ?.data ?.data ?? [];
                }
            });
        },
        async vieAppointmentFullDetailsHandler(appointmentReference) {

            await this.resetAppointments();
            await this.$router.push({
                name: 'appAppointmentList',
                params: {
                    appointmentReference: appointmentReference,

                }
            });

        },
        async viewOrderFullDetailsHandler(orderNo) {
            await this.$router.push({
                name: 'appOrderList',
                params: {
                    orderNo: orderNo,

                }
            });

        },
        async openDashboardSummerySingleViewModal(panel) {
            this.currentSelectedPanel = await {};
            this.currentSelectedPanel = await panel;
            document.querySelector('[data-target="#dashboardSummerySingleViewModal"]').click();
        },
        calculateOrderCharges(singleOrder) {
            const decreaseType = ['Discount', 'Coupon']
            if (singleOrder.status === 'Pending') {
                return singleOrder ?.details ?.grand_total ?? 0;
            } else {
                return singleOrder.orderCharges.reduce(function (accu, cur) {
                    accu = (decreaseType.includes(cur.type)) ? accu - cur.amount : accu + cur.amount
                    return accu
                }, 0);
            }

        },
        calculateOrderPayments(singleOrder) {
            if (singleOrder.status === 'Pending') {
                return singleOrder ?.orderPaymentsByReference.reduce(function (acc, cur) {
                    return acc + cur ?.total_transaction ?? 0
                }, 0);
            } else {
                return singleOrder ?.orderPayments.reduce(function (accu, cur) {
                    return (!('payment' in cur) || cur.payment === null) ? 0 : accu + cur ?.payment ?.total_transaction ?? 0
                }, 0);
            }

        },

        userFullAddress(customerObj) {
            let businessName = customerObj ?.address ?.business ?.name ?? '';
            let addressStreet = customerObj ?.address ?.street ?? '';
            let addressSuburb = customerObj ?.address ?.suburb ?? '';
            let addressState = customerObj ?.address ?.state.toUpperCase() ?? '';
            let addressPostCode = customerObj ?.address ?.post_code ?? '';
            let addressCountry = customerObj ?.address ?.country ?? '';
            return customerObj ?.type === 'Business' ?
                `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
                `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
        },

        getDisplayDate(date) {
            let newDate = date ? new Date(date) : new Date;
            return new Date(newDate).toLocaleDateString("en-AU", {
                day: "numeric",
                month: "short",
                year: "numeric",
            })
        },
        getOrderChargesDetails(singleOrder) {
            /* const chargesArray = [
               'gst_charge',
               'order_delivery_charge',
               'applied_discount',
               'coupon_discount',
               'credited_payment_discount',
             ];

             return chargesArray.filter((chargeName) =>
                 chargeName in singleOrder.details && singleOrder.details[chargeName].applied_status === true).map((chargeName) => {
               return {name: chargeName, amount: singleOrder.details[chargeName].amount}
             });*/
            let orderItemsPrice = 0,
                orderGST = 0,
                orderDiscount = 0,
                orderSurCharge = 0,
                orderDeliveryCharge = 0,
                discountDetails = [];
            let appliedDiscount = 0,
                couponDiscount = 0,
                creditedPaymentDiscount = 0;

            if (singleOrder.status === 'Pending') {
                orderGST = singleOrder.details['gst_charge'].applied_status === true ? singleOrder.details['gst_charge'].amount : 0;
                orderDeliveryCharge = singleOrder.details['order_delivery_charge'].applied_status === true ? singleOrder.details['order_delivery_charge'].amount : 0;
                appliedDiscount = singleOrder.details['applied_discount'].applied_status === true ? singleOrder.details['applied_discount'].amount : 0;
                couponDiscount = singleOrder.details['coupon_discount'].applied_status === true ? singleOrder.details['coupon_discount'].amount : 0;
                creditedPaymentDiscount = singleOrder.details['credited_payment_discount'].applied_status === true ? singleOrder.details['credited_payment_discount'].amount : 0;
                orderDiscount = appliedDiscount + couponDiscount + creditedPaymentDiscount;
                orderItemsPrice = singleOrder.details['subtotal_amount'] ?? 0;
                orderSurCharge = singleOrder.details['surcharge'] ?? 0
                discountDetails = [{
                        name: 'applied discount',
                        amount: appliedDiscount
                    },
                    {
                        name: 'coupon discount',
                        amount: couponDiscount
                    },
                    {
                        name: 'credited payment discount',
                        amount: creditedPaymentDiscount
                    },
                ]
            } else {
                singleOrder.orderCharges.forEach((charge) => {
                    if (charge.type === 'GST') {
                        orderGST = orderGST + charge ?.amount ?? 0
                    }
                    if (charge.type === 'Surcharge') {
                        orderSurCharge = orderSurCharge + charge ?.amount ?? 0
                    }
                    if (charge.type === 'Items Price') {
                        orderItemsPrice = orderItemsPrice + charge ?.amount ?? 0
                    }
                    if (charge.type === 'Delivery Charge') {
                        orderDeliveryCharge = orderDeliveryCharge + charge ?.amount ?? 0
                    }
                    if (charge.type === 'Discount') {
                        orderDiscount = orderDiscount + charge ?.amount ?? 0
                        discountDetails.unshift({
                            name: charge.name,
                            amount: charge.amount
                        })
                    }
                });
            }

            return [{
                    name: 'items price',
                    sign: '+',
                    amount: orderItemsPrice
                },
                {
                    name: 'surcharge',
                    sign: '+',
                    amount: orderSurCharge
                },
                {
                    name: 'discount',
                    sign: '-',
                    amount: orderDiscount,
                    details: discountDetails
                },
                {
                    name: 'GST',
                    sign: '+',
                    amount: orderGST
                },
                {
                    name: 'delivery charge',
                    sign: '+',
                    amount: orderDeliveryCharge
                },
            ]

        },
        calculateOrderRefunds(singleOrder) {
            if (singleOrder.orderRefundsByReference.length > 0) {
                const amount = singleOrder ?.orderRefundsByReference.filter((order) => order.status === 'Refunded').reduce((acc, cur) => acc + cur.amount, 0);
                return {
                    approved_status: true,
                    amount: amount
                };
            }
            return {
                approved_status: false,
                amount: 0
            };
        },
        getOrderCalculation() {
            this.orders.map((order) => {
                order.details = JSON.parse(order.details);
                let orderCharge = this.calculateOrderCharges(order);
                let orderPayment = this.calculateOrderPayments(order);
                //console.log(orderPayment)
                let orderChargesDetails = this.getOrderChargesDetails(order);
                //console.log(orderChargesDetails);
                let orderRefund = this.calculateOrderRefunds(order);

                let remaining = orderCharge - orderPayment;
                this.payments[order.id] = {
                    total: orderCharge,
                    paid: orderPayment,
                    remaining: remaining,
                    chargesDetails: orderChargesDetails
                };
                this.refunds[order.id] = {
                    status: orderRefund.approved_status,
                    amount: orderRefund.amount
                }
                // this.orderChargesDetails[order.id] = orderChargesDetails;
            });
        },
        async getTodayCreationOrderList() {
            this.getOrdersParams.order_date[0] = this.currentDate();
            this.loader(true);
            await this.getOrders(this.getOrdersParams)
                .then((response) => {
                    if (response && response.message && response.status !== 200) {
                        this.loader(false);
                        this.showToastMessage(response);
                    } else {
                        this.getOrderCalculation();
                        this.loader(false);
                    }
                });
        },

    },
    async beforeMount() {
        await this.resetAppointments();
        await this.resetOrders();
    },
    async mounted() {
        await this.getUserTypes();
        this.panelBaseTodayAppointmentsReportHandler();
        this.panelBaseWeeklyAppointmentsReportHandler();
        this.panelBaseMonthlyAppointmentsReportHandler();
        this.panelBaseYearlyAppointmentsReportHandler();
        this.getTodayCreatedAppointemntsList();
        this.getTodayOngoingAppointemntsList();
      
        this.getTodayCreationOrderList();

    },

}
</script>

<style scoped>
@import './appDashboard.css';
</style>
