<template>
<div class="modal fade " id="dashboardSummerySingleViewModal" tabindex="-1" role="dialog" aria-labelledby="dashboardSummerySingleViewModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title white" id=""> {{currentSelectedPanel.label}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="">
                    <div style="margin-top: .5rem">
                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                                <input v-model="graphicalView" type="checkbox" class="custom-control-input" checked id="isRescheduledByCustomer">
                                <label class="custom-control-label mr-1" for="isRescheduledByCustomer"></label>
                            </div>
                            <span class="font-medium-1">{{ graphicalView ? "Graphical view" : "Normal view" }}</span>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="" v-if="currentSelectedPanel?.panelFor === 'month'" >
                        <div :class="graphicalView ? 'hidden mt-1' : 'mt-1'">
                            <h6 v-if="currentSelectedPanel?.labelTitle?.[0]">{{currentSelectedPanel?.labelTitle?.[0]}}</h6>
                            <table class="table table-hover text-secondary table-md mb-0 table-responsive table-bordered w-100">
                                <thead>
                                    <tr>
                                        <th style="width: 14.5%;" v-for="(label,index) in  currentSelectedPanel?.dates?.[0]" :key="index" scope="">{{label}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-for="(panel,index) in  currentSelectedPanel?.datasets?.[0]" :key="index">{{panel}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr>
                            <hr>
                            <div>
                                <h6 v-if="currentSelectedPanel?.labelTitle?.[1]">{{currentSelectedPanel?.labelTitle?.[1]}}</h6>
                            </div>
                            <table class="table table-hover text-secondary table-md mb-0 table-responsive table-bordered w-100">
                                <thead>
                                    <tr>
                                        <th  style="width: 14.5%;" v-for="(label,index) in  currentSelectedPanel?.dates?.[1]" :key="index" scope="">{{label}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-for="(panel,index) in  currentSelectedPanel?.datasets?.[1]" :key="index">{{panel}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                 
                    <div v-if="currentSelectedPanel?.panelFor === 'week'" :class="graphicalView ? 'hidden mt-1' : 'mt-1'">
                        <h6 v-if="currentSelectedPanel?.labelTitle?.[0]">{{currentSelectedPanel?.labelTitle?.[0]}}</h6>
                        <table class="table table-hover text-secondary table-md mb-0 table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th  style="width: 14.5%;" v-for="(label,index) in  currentSelectedPanel?.labels" :key="index" >{{label}}</th>
                                </tr>
                                <tr>
                                    <th  style="width: 14.5%;" v-for="(date,index) in  currentSelectedPanel?.dates?.[0]" :key="index" >{{date}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-for="(panel,index) in  currentSelectedPanel?.datasets?.[0]" :key="index">{{panel}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="mt-2">
                            <h6 v-if="currentSelectedPanel?.labelTitle?.[1]">{{currentSelectedPanel?.labelTitle?.[1]}}</h6>
                        </div>
                        <table class="table table-hover text-secondary table-md mb-0 table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th  style="width: 14.5%;" v-for="(label,index) in  currentSelectedPanel?.labels" :key="index" >{{label}}</th>
                                </tr>
                                <tr>
                                    <th  style="width: 14.5%;" v-for="(date,index) in  currentSelectedPanel?.dates?.[1]" :key="index" >{{date}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-for="(panel,index) in  currentSelectedPanel?.datasets?.[1]" :key="index">{{panel}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="currentSelectedPanel?.panelFor === 'recent'" :class="graphicalView ? 'hidden mt-1' : 'mt-1'">
                        <h6 v-if="currentSelectedPanel?.labelTitle?.[0]">{{currentSelectedPanel?.labelTitle}}</h6>
                        <table class="table table-hover text-secondary table-md mb-0 table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th v-for="(label,index) in  currentSelectedPanel?.labels" :key="index" scope="col">{{label}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-for="(panel,index) in  currentSelectedPanel?.datasets" :key="index">{{panel}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12" :class="!graphicalView ? 'hidden mt-1' : 'mt-1'">
                        <div id="dashboardSummerySingleViewCanvasIdParent">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 py-1">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Chart from 'chart.js/auto';
export default {
    name: "DashboardSummerySingleView",
    props: ['currentSelectedPanel'],
    data() {
        return {
            graphicalView: false,
        }
    },
    watch: {
        async currentSelectedPanel(panel) {
            console.log(panel, "panel");
            document.querySelector("#dashboardSummerySingleViewCanvasIdParent").innerHTML = await ' <canvas id="dashboardSummerySingleViewCanvasId" height="200px"></canvas>';
            new Chart(document.getElementById('dashboardSummerySingleViewCanvasId'), this.generateChart({
                type: panel.chartType,
                data: panel.datasets
            }, panel.labels, panel.chartName, panel.labelTitle, true, ['#4bc0c0', '#36a2eb']));
        }
    },
    methods: {
        generateChart(dataset, labels, type, label, animation, colors) {
            const chart = {
                type: type,
                data: {
                    labels: labels,
                    datasets: [],
                },
                options: {
                    responsive: true,
                    showAllTooltips: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            };
            if (dataset.type === 'double') {
                dataset.data.map((item, index) => {
                    chart.data.datasets.push({
                        label: label[index],
                        fill: false,
                        data: dataset.data[index],
                        backgroundColor: [
                            `${colors[index]}`,
                        ],
                        borderColor: [
                            `${colors[index]}`,
                        ],
                        borderWidth: 2
                    });
                })
            } else {
                chart.data.datasets.push({
                    label: label,
                    // fill: false,
                    data: dataset.data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 2
                });
            }
            if (animation) {
                chart.animation = {
                    tension: {
                        easing: 'linear',
                        from: 1,
                        to: 0,
                    }
                }
            }
            return chart;
        },
    },
}
</script>
